import Copyright from "../components/Copyright";
import CreateBhutanTourPackages from "../components/CreateBhutanTourPackages";
import BhutanTourPackages from "../components/BhutanTourPackages";
import Footer from "../components/Footer";
function Bhutan() {
  return (
    <div className="Bhutan">


<div id="post_header" className="container" style={{height: '240px'}}>
</div>


<div id="content">

  <BhutanTourPackages/>

</div>

<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<div id="content-bottom-border" className="container">
</div>
<CreateBhutanTourPackages/>


<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default Bhutan;