import React, { useState } from 'react';
import { OWNPackagesRef } from '../js/firebase'; 
import { set, push } from 'firebase/database';


export default function CreateOwnTourPackages() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    guests: '',
    adults: 0,
    kids: 0,
    infants: 0,
    arrival: '',
    departure: '',
    days: [],
    activities: {} // Store activities for each day
  });

  const [error, setError] = useState('');

  const calculateDays = (arrival, departure) => {
    const startDate = new Date(arrival);
    const endDate = new Date(departure);

    if (arrival && departure && startDate <= endDate) {
      const totalDays = Math.floor((endDate - startDate) / (1000 * 3600 * 24)) + 1;

      const daysWithDates = Array.from({ length: totalDays }, (_, i) => {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);
        return `Day ${i + 1}: ${currentDate.toDateString()}`;
      });

      setFormData((prevData) => ({
        ...prevData,
        days: daysWithDates,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    
    // Call calculateDays when both arrival and departure are set
    if (name === 'arrival' || name === 'departure') {
      const arrivalDate = name === 'arrival' ? value : formData.arrival;
      const departureDate = name === 'departure' ? value : formData.departure;
      if (arrivalDate && departureDate) {
        calculateDays(arrivalDate, departureDate);
      }
    }
  };

  // Handle activity input changes
  const handleActivityChange = (e, dayIndex) => {
    const activity = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      activities: { ...prevData.activities, [`day${dayIndex + 1}`]: activity },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newPackageRef = push(OWNPackagesRef);
    await set(newPackageRef, {
      ...formData,
      timestamp: new Date().toString(),
    });

    setFormData({
      name: '',
      phone: '',
      guests: 1,
      adults: 0,
      kids: 0,
      infants: 0,
      arrival: '',
      departure: '',
      days: [],
      activities: {},
    });
    setError('');
  };

  return (
    <div>
      <div className="container background-white" style={{ padding: '20px' }}>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <h2 className="mt-12 mb-12 text-center">Create Your Own Tour Package</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit} style={{ padding: '20px', borderRadius: '10px' }}>
              
              <div className="mb-3">
                <label className="form-label">Customer Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  minLength={10}
                  maxLength={10}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Number of Guests</label>
                <input
                  type="number"
                  className="form-control"
                  name="guests"
                  value={formData.guests}
                  onChange={handleChange}
                  required
                />
              </div>

              {formData.guests > 1 && (
                <div>
                  <div className="mb-3">
                    <label className="form-label">Adults</label>
                    <input
                      type="number"
                      className="form-control"
                      name="adults"
                      value={formData.adults}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Kids</label>
                    <input
                      type="number"
                      className="form-control"
                      name="kids"
                      value={formData.kids}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Infants</label>
                    <input
                      type="number"
                      className="form-control"
                      name="infants"
                      value={formData.infants}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              )}

              <div className="mb-3">
                <label className="form-label">Date of Arrival</label>
                <input
                  type="date"
                  className="form-control"
                  name="arrival"
                  value={formData.arrival}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Date of Departure</label>
                <input
                  type="date"
                  className="form-control"
                  name="departure"
                  value={formData.departure}
                  onChange={handleChange}
                  required
                />
              </div>

              {formData.days.length > 0 &&
                formData.days.map((day, index) => (
                  <div className="mb-3" key={index}>
                    <label className="form-label">{day}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Activity for ${day}`}
                      onChange={(e) => handleActivityChange(e, index)}
                      required
                    />
                  </div>
                ))}

              <p className="animate fadeInUp text-center">
                <button type="submit" className="btn btn-primary btn-lg">
                  Submit
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
