// Import Firebase core and modules
import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';
import { getAuth } from 'firebase/auth'; // Import Firebase Authentication

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDm07fmi40YU_Zlru72wPYmiJE0QxhkLxQ",
  authDomain: "rambleblogs-8868c.firebaseapp.com",
  projectId: "rambleblogs-8868c",
  storageBucket: "rambleblogs-8868c.appspot.com",
  messagingSenderId: "801487843326",
  appId: "1:801487843326:web:fe6a217add3091603a5aaf",
  measurementId: "G-7T6L95NH7N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Database and Authentication
const db = getDatabase(app);
const auth = getAuth(app); // Initialize Firebase Authentication

// Database references
const rambleBlogsRef = ref(db, 'rambleblogs');
const DJPackagesRef = ref(db, 'DJPackages');
const SikkimPackagesRef = ref(db, 'SikkimPackages');
const DisDarjeelingRef = ref(db, 'DisDarjeeling');
const DisSikkimRef = ref(db, 'DisSikkim');
const DJTPackagesRef = ref(db, 'DJTPackages');
const SKTPackagesRef = ref(db, 'SKTPackages');
const KLTPackagesRef = ref(db, 'KLTPackages');
const DKTPackagesRef = ref(db, 'DKTPackages');
const NPTPackagesRef = ref(db, 'NPTPackages');
const BTTPackagesRef = ref(db, 'BTTPackages');
const OWNPackagesRef = ref(db, 'OWNPackages');

// Export database references and auth object
export { rambleBlogsRef, DJPackagesRef, DJTPackagesRef, DKTPackagesRef, KLTPackagesRef, OWNPackagesRef, NPTPackagesRef, BTTPackagesRef, SKTPackagesRef, SikkimPackagesRef, DisDarjeelingRef, DisSikkimRef, auth };
