import React, { useState, useEffect } from 'react';
import { onValue } from 'firebase/database';
import { DJTPackagesRef } from '../js/firebase';

export default function DarjeelingTourPackages() {
  const [packages, setPackages] = useState([]);
  const [expandedPackageId, setExpandedPackageId] = useState(null);

  useEffect(() => {
    const unsubscribe = onValue(DJTPackagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPackages(Object.keys(data).map((key) => ({ id: key, ...data[key] })));
      } else {
        setPackages([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const toggleExpand = (packageId) => {
    if (expandedPackageId === packageId) {
      setExpandedPackageId(null); // Collapse if already expanded
    } else {
      setExpandedPackageId(packageId); // Expand the clicked package
    }
  };

  const renderContent = (content) => {
    return { __html: content };
  };

  const phoneNumber = '918509631121'; // WhatsApp number
  const createWhatsAppLink = (pkgName) => {
    const message = `Interested in the package: ${pkgName}`;
    return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  };

  return (
    <div>
      {packages.map((pkg, index) => (
        <React.Fragment key={pkg.id}>
          <div className="container background-white">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <br />
                <hr />

                <div style={{ textAlign: 'center' }}>
                  {/* Display package name in bold and clickable */}
                  <p
                    style={{ margin: '2px 0', cursor: 'pointer', fontWeight: 'bold' }}
                    onClick={() => toggleExpand(pkg.id)}
                  >
                    {pkg.package}
                  </p>

                  {/* Display Itinerary and Submitted by if the package is expanded */}
                  {expandedPackageId === pkg.id && (
                    <div>
                      <p style={{ margin: '2px 0' }}>
                        <strong>Itinerary:</strong> <span dangerouslySetInnerHTML={renderContent(pkg.itinerary)} />
                      </p>
                      <p style={{ margin: '2px 0' }}>
                        <strong>Shared By:</strong> {pkg.name}
                      </p>
                      <p style={{ margin: '2px 0' }}>
                        <small className="text-muted">{new Date(pkg.timestamp).toLocaleString()}</small>
                      </p>
                    </div>
                  )}
                </div>
                <hr />
              </div>
            </div>

            {/* WhatsApp button with package name */}
            <p className="animate fadeInUp text-center">
              <a href={createWhatsAppLink(pkg.package)} target="_blank" rel="noopener noreferrer">
                <button className="btn btn-primary btn-lg">WhatsApp</button>
              </a>
            </p>
          </div>

          {index < packages.length - 1 && (
            <div className="container" style={{ height: '20px' }}></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
