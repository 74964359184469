import Copyright from "../components/Copyright";
import Details from "../components/Details";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hire from "../components/Hire";
import Main from "../components/Main";
import Package from "../components/Package";
import Slide from "../components/Slide";



function Home() {
  return (
    <div className="Home">
      
      
      


  
 <Header/>

{/* End Top Menu */}
<div id="post_header" className="container" style={{height: '240px'}}>
</div>
<div id="content-top-border" className="container">
</div>
{/* === END HEADER === */}
{/* === BEGIN CONTENT === */}


<div id="content">

  <Slide/>


  
  <div id="content-bottom-border" className="container">
  </div>

  
  <div id="pre-header" className="container" style={{height: '240px'}}>
  </div>
  <div id="content-bottom-border" className="container">
  </div>
  

  <Main/>


  <div id="content-bottom-border" className="container">
  </div>
  <div id="pre-header" className="container" style={{height: '240px'}}>
  </div>
  <div id="content-bottom-border" className="container">
  </div>

<Details/>

  <div id="content-bottom-border" className="container">
  </div>
  <div id="pre-header" className="container" style={{height: '240px'}}>
  </div>
  <div id="content-bottom-border" className="container">
  </div>




<Package/>



  <div id="content-bottom-border" className="container">
  </div>
  <div id="pre-header" className="container" style={{height: '240px'}}>
  </div>
  <div id="content-bottom-border" className="container">
  </div>

  
 <Hire/>






</div>
{/* === END CONTENT === */}
{/* === BEGIN FOOTER === */}
<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>

{/* Footer Menu */}

<Copyright/>


</div>






);
}
export default Home;