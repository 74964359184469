import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home.jsx";
import Blogs from "./pages/Blogs.jsx";
import Darjeeling from "./pages/Darjeeling.jsx";
import Sikkim from "./pages/Sikkim.jsx";
import DisDarjeeling from "./pages/DisDarjeeling.jsx";
import DisSikkim from "./pages/DisSikkim.jsx";
import DarjeelingTour from "./pages/DarjeelingTour.jsx";
import SikkimTour from "./pages/SikkimTour.jsx";
import KalimpongTour from "./pages/KalimpongTour.jsx";
import DwoorsTour from "./pages/DwoorsTour.jsx";
import NepalTour from "./pages/NepalTour.jsx";
import BhutanTour from "./pages/BhutanTour.jsx";
import OwnTour from "./pages/OwnTour.jsx";


import Contact from "./pages/Contact.jsx";

function App() {

  useEffect(() => {
    let titleText = "Welcome to Ramble Networks | Customized Tour Packages | Darjeeling Tour Packages | Sikkim Darjeeling Tour Packages | Kalimpong Darjeeling Tour Packages | Dwoors Darjeeling Tour Packages | Nepal Darjeeling Tour Packages | Bhutan Darjeeling Tour Packages | ";
    let speed = 200; // Speed of the scrolling (milliseconds)

    function scrollTitle() {
      document.title = titleText;
      titleText = titleText.substring(1) + titleText.charAt(0); // Rotate the text
      setTimeout(scrollTitle, speed);
    }

    scrollTitle(); // Start the scrolling effect when the component mounts
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Darjeeling" element={<Darjeeling />} />
        <Route path="/Sikkim" element={<Sikkim />} />
        <Route path="/DisDarjeeling" element={<DisDarjeeling />} />
        <Route path="/DisSikkim" element={<DisSikkim />} />

        <Route path="/Contact" element={<Contact />} />
        <Route path="/DarjeelingTourPackage" element={<DarjeelingTour />} />
        <Route path="/SikkimTourPackage" element={<SikkimTour />} />
        <Route path="/KalimpongTourPackage" element={<KalimpongTour />} />
        <Route path="/DwoorsTourPackage" element={<DwoorsTour />} />
        <Route path="/NepalTourPackage" element={<NepalTour />} />
        <Route path="/BhutanTourPackage" element={<BhutanTour />} />
        <Route path="/OwnTourPackage" element={<OwnTour />} />


      </Routes>
    </div>
  );
}

export default App;
