import React, { useState, useEffect } from 'react';
import { onValue } from 'firebase/database';
import { DisSikkimRef } from '../js/firebase';

export default function SikkimDis() {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const unsubscribe = onValue(DisSikkimRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPackages(Object.keys(data).map((key) => ({ id: key, ...data[key] })));
      } else {
        setPackages([]);
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  const renderContent = (content) => {
    return { __html: content }; // Use the dangerouslySetInnerHTML attribute
  };

  const phoneNumber = '918509631121'; // Replace with your actual phone number
  const message = 'Interested'; // Replace with your desired message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div>
      {packages.map((pkg, index) => (
        <React.Fragment key={pkg.id}>

<div id="content-bottom-border" className="container">
</div>
          <div className="container background-white">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <br />
                <h2 className="mt-4 mb-4 text-center">Locations : Sikkim | Kurseong | Mirik | Dwoors | Siliguri</h2>
                <hr />
                <div style={{ textAlign: 'center' }}>
                  
                  <p style={{ margin: '2px 0' }}>
                    <strong>Location:</strong> <span dangerouslySetInnerHTML={renderContent(pkg.itinerary)} />
                  </p>
                  <p style={{ margin: '2px 0' }}>
                    <strong>Shared By :</strong> {pkg.name}
                  </p>
                  <p style={{ margin: '2px 0' }}>
                    <small className="text-muted">{new Date(pkg.timestamp).toLocaleString()}</small>
                  </p>
                </div>
                <hr />
              </div>
            </div>

            <p className="animate fadeInUp text-center">
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
        <button className="btn btn-primary btn-lg">WhatsApp</button>
      </a>
    </p>
          </div>
          <div id="content-bottom-border" className="container">
</div>
          

          {index < packages.length - 1 && (
            <div id="pre-header" className="container" style={{ height: '100px' }}></div>
          )}
      
      
        </React.Fragment>
        

        
      ))}

      
    </div>
  );
}
