        import React, { useState, useEffect } from 'react';
        import Slider from 'react-slick';
        import 'slick-carousel/slick/slick.css';
        import 'slick-carousel/slick/slick-theme.css';
        import styled from 'styled-components';
        
        const SlideContainer = styled.div`
          /* Add any container-specific styles here */
        `;
        
        const SlideImageContainer = styled.div`
          width: 100%;
          height: auto;
          object-fit: cover;
        `;
        
        const SlideImage = styled.img`
          width: 100%;
          height: 100%;
          object-fit: cover;
        `;
        
        export default function Slide() {
          const slides = [
            'assets/img/slideshow/slide1.jpg',
            'assets/img/slideshow/slide2.jpg',
            'assets/img/slideshow/slide3.jpg',
            'assets/img/slideshow/slide4.jpg',
            'assets/img/slideshow/slide5.jpg',
            'assets/img/slideshow/slide6.jpg',
            'assets/img/slideshow/slide7.jpg',
            'assets/img/slideshow/slide8.jpg',
            'assets/img/slideshow/slide9.jpg',
            'assets/img/slideshow/slide10.jpg',
            'assets/img/slideshow/slide11.jpg',
            'assets/img/slideshow/slide12.jpg',
            'assets/img/slideshow/slide13.jpg',
            'assets/img/slideshow/slide14.jpg',
            'assets/img/slideshow/slide15.jpg',
            'assets/img/slideshow/slide16.jpg',
            'assets/img/slideshow/slide17.jpg',
            'assets/img/slideshow/slide18.jpg',
              ];
        
          // eslint-disable-next-line no-unused-vars
          const [currentSlide, setCurrentSlide] = useState(0);
        
          useEffect(() => {
            const intervalId = setInterval(() => {
              // Increment the currentSlide index, and loop back to 0 when reaching the end
              setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            }, 2000); // Adjust the interval as needed (in milliseconds)
        
            return () => {
              clearInterval(intervalId); // Cleanup the interval on component unmount
            };
          }, [slides.length]);
        
          const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            beforeChange: (oldIndex, newIndex) => {
              setCurrentSlide(newIndex);
            },
          };
        
          return (
            <SlideContainer>
              <div className="container no-padding background-gray-lighter">
                <Slider {...settings}>
                  {slides.map((slide, index) => (
                    <div key={index}>
                      <SlideImageContainer>
                        <SlideImage src={slide} alt={`Slide ${index + 1}`} />
                      </SlideImageContainer>
                    </div>
                  ))}
                </Slider>
              </div>
            </SlideContainer>
          );
        }
        