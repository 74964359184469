import React, { useState } from 'react';

export default function Details() {
  const [formData, setFormData] = useState({
    numberOfGuests: '',
    adults: '',
    kids: '',
    kidsAge: Array(3).fill(''),
    arrivalDate: '',
    departureDate: '',
    preferredLocations: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleKidsAgeChange = (index, value) => {
    const updatedKidsAge = [...formData.kidsAge];
    updatedKidsAge[index] = value;
    setFormData((prevData) => ({ ...prevData, kidsAge: updatedKidsAge }));
  };

  const openWhatsApp = () => {
    const message = `
  *New Tour Itinerary Request* :
  
  NumberOfGuests: ${formData.numberOfGuests}
  Adults: ${formData.adults}
  Kids: ${formData.kids}
  KidsAge: ${formData.kidsAge.join(', ')}
  ArrivalDate: ${formData.arrivalDate}
  DepartureDate: ${formData.departureDate}
  PreferredLocations: ${formData.preferredLocations}
  `;
  
    const url = `https://wa.me/918509631121?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  
  

  const handleSubmit = (e) => {
    e.preventDefault();
    // Your form submission logic here

    // For demonstration purposes, simulate a successful form submission
    setFormSubmitted(true);

    // Reset the form after submission (optional)
    setFormData({
      numberOfGuests: '',
      adults: '',
      kids: '',
      kidsAge: Array(3).fill(''),
      arrivalDate: '',
      departureDate: '',
      preferredLocations: '',
    });

    // Reset the form submission status after 3 seconds
    setTimeout(() => {
      setFormSubmitted(false);
    }, 3000);
  };

  return (
    <div className="container background-white">
      <div className="row margin-vert-30">
        <div className="col-md-12">
          <h2 className="text-center mb-4 display-4">Get Your Free Tour Itinerary</h2>

          {!formSubmitted ? (
            <form onSubmit={handleSubmit}>
              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="numberOfGuests" className="form-label fw-bold fs-5 me-2">
                  Number of Guest(s):
                </label>
                <input
                  type="text"
                  id="numberOfGuests"
                  name="numberOfGuests"
                  value={formData.numberOfGuests}
                  onChange={handleChange}
                  className="form-control fs-5"
                  placeholder="E.g., 2"
                  required
                />

                <label htmlFor="adults" className="form-label fw-bold fs-5 mx-2">
                  Adult(s):
                </label>
                <input
                  type="text"
                  id="adults"
                  name="adults"
                  value={formData.adults}
                  onChange={handleChange}
                  className="form-control fs-5"
                  placeholder="Adults"
                  required
                />

                <label htmlFor="kids" className="form-label fw-bold fs-5 mx-2">
                  Kid(s):
                </label>
                <input
                  type="text"
                  id="kids"
                  name="kids"
                  value={formData.kids}
                  onChange={handleChange}
                  className="form-control fs-5"
                  placeholder="Kids"
                  required
                />
              </div>
              {formData.kids > 0 && (
                <div className="mb-3 d-flex">
                  {Array.from({ length: formData.kids }, (_, index) => (
                    <input
                      key={index}
                      type="text"
                      value={formData.kidsAge[index]}
                      onChange={(e) => handleKidsAgeChange(index, e.target.value)}
                      className="form-control fs-5 mx-2"
                      placeholder={`Kid ${index + 1} Age`}
                      required
                    />
                  ))}
                </div>
              )}

              <div className="mb-3 d-flex">
                <div className="me-2" style={{ flex: 1 }}>
                  <label htmlFor="arrivalDate" className="form-label fw-bold fs-5">
                    Date of Arrival:
                  </label>
                  <input
                    type="date"
                    id="arrivalDate"
                    name="arrivalDate"
                    value={formData.arrivalDate}
                    onChange={handleChange}
                    className="form-control fs-5"
                    required
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label htmlFor="departureDate" className="form-label fw-bold fs-5">
                    Date of Departure:
                  </label>
                  <input
                    type="date"
                    id="departureDate"
                    name="departureDate"
                    value={formData.departureDate}
                    onChange={handleChange}
                    className="form-control fs-5"
                    required
                  />
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="preferredLocations" className="form-label fw-bold fs-5">
                  Preferred Location(s):
                </label>
                <textarea
                  id="preferredLocations"
                  name="preferredLocations"
                  value={formData.preferredLocations}
                  onChange={handleChange}
                  className="form-control fs-5"
                  rows="3"
                  required
                />
              </div>
              <br/>
              <p className="animate fadeInUp text-center">

              <button className="btn btn-primary btn-lg" onClick={openWhatsApp}>Get Itinerary</button>

              </p>
            </form>
          ) : (
            <p className="text-center mt-4 text-success fw-bold fs-4">
              We have received your details. We will get back to you shortly.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
