import Copyright from "../components/Copyright";
import CreateNepalTourPackages from "../components/CreateNepalTourPackages";
import NepalTourPackages from "../components/NepalTourPackages";
import Footer from "../components/Footer";
function Nepal() {
  return (
    <div className="Nepal">


<div id="post_header" className="container" style={{height: '240px'}}>
</div>


<div id="content">

  <NepalTourPackages/>

</div>

<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<div id="content-bottom-border" className="container">
</div>
<CreateNepalTourPackages/>


<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default Nepal;