import React, { useState, useEffect } from 'react';
import { SikkimPackagesRef } from '../js/firebase';
import { set, onValue, push } from 'firebase/database';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CreateSikkimPackages() {
  const [formData, setFormData] = useState({
    name: '',
    itinerary: '',
  });

  useEffect(() => {
    onValue(SikkimPackagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // If you don't use 'blogs', you can remove this part
        // setBlogs(Object.keys(data).map((key) => ({ id: key, ...data[key] })));
      } else {
        // If you don't use 'blogs', you can remove this part
        // setBlogs([]);
      }
    });
  }, []);

  const handleContentChange = (value) => {
    setFormData((prevData) => ({ ...prevData, itinerary: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newBlogRef = push(SikkimPackagesRef);
    await set(newBlogRef, {
      ...formData,
      timestamp: new Date().toString(),
    });
    setFormData({ name: '', itinerary: '' });
  };

  return (
    <div>
      <div className="container background-white" style={{ padding: '20px' }}>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <br />
            <h2 className="mt-12 mb-12 text-center">Share Your Sikkim Tour Package</h2>
            <form
              onSubmit={handleSubmit}
              style={{ backgroundColor: '', padding: '20px', borderRadius: '10px' }}
            >
              <div key="name" className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Itinerary</label>
                <ReactQuill
                  value={formData.itinerary}
                  onChange={handleContentChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link', 'image'],
                      ['clean'],
                    ],
                  }}
                  formats={[
                    'header',
                    'bold', 'italic', 'underline', 'strike',
                    'list', 'bullet',
                    'link', 'image',
                  ]}
                  style={{ height: '200px' }} // Adjust the height as needed
                  required
                />
              </div>

              <br /> <br /> <br /> <br />

              <p className="animate fadeInUp text-center">
                <button type="submit" className="btn btn-primary btn-lg">
                  Submit
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
