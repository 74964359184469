import React from 'react'

export default function Package() {
  return (
    <div>
        
        
    <div className="container background-gray-lighter">
      <div className="row padding-vert-20">
        <div className="col-md-1">
        </div>
        <div className="col-md-10">
          {/* Portfolio */}
          <ul className="portfolio-group">
            {/* Portfolio Item */}

            <li className="portfolio-item col-sm-6 col-xs-12 padding-20">
              <a href="/DarjeelingTour">
                <figure className="animate fadeInLeft">
                  <img alt="image1" src="assets/img/frontpage/image1.jpg" />
                  <figcaption>
                    <h3>Darjeeling Tour Packages</h3>
                    <span>Breathtaking landscapes, aromatic teas and cultural gems await. Let every moment be an adventure in the lap of the Himalayas.</span>
                  </figcaption>
                </figure>
              </a>
            </li>
            {/* //Portfolio Item// */}
            {/* Portfolio Item */}
            <li className="portfolio-item col-sm-6 col-xs-12 padding-20">
              <a href="/SikkimTour">
                <figure className="animate fadeInRight">
                  <img alt="image2" src="assets/img/frontpage/image2.jpg" />
                  <figcaption>
                    <h3>Sikkim Tour Packages</h3>
                    <span>Where vibrant cultures, pristine landscapes, and Himalayan serenity blend seamlessly. Your Sikkim adventure begins here.</span>
                  </figcaption>
                </figure>
              </a>
            </li>
            {/* //Portfolio Item// */}
            {/* Portfolio Item */}
            <li className="portfolio-item col-sm-6 col-xs-12 padding-20">
              <a href="/DwoorsTour">
                <figure className="animate fadeInLeft">
                  <img alt="image3" src="assets/img/frontpage/image3.jpg" />
                  <figcaption>
                    <h3>Dooars Tour Packages</h3>
                    <span>Explore lush jungles, encounter diverse wildlife, and immerse yourself in nature's grandeur. Unveil Dooars' beauty today!</span>
                  </figcaption>
                </figure>
              </a>
            </li>
            {/* //Portfolio Item// */}
            {/* Portfolio Item */}
            
            
            <li className="portfolio-item col-sm-6 col-xs-12 padding-20">
              <a href="/KalimpongTour">
                <figure className="animate fadeInLeft">
                  <img alt="image4" src="assets/img/frontpage/image4.jpg" />
                  <figcaption>
                    <h3>Kalimpong Tour Packages</h3>
                    <span>From scenic landscapes to cultural delights, our Kalimpong tours promise an unforgettable experience. Immerse yourself in the charm and beauty of Kalimpong!</span>
                  </figcaption>
                </figure>
              </a>
            </li>
            {/* //Portfolio Item// */}
            {/* Portfolio Item */}
            <li className="portfolio-item col-sm-6 col-xs-12 padding-20">
              <a href="/BhutanTour">
                <figure className="animate fadeInRight">
                  <img alt="image6" src="assets/img/frontpage/image6.jpg" />
                  <figcaption>
                    <h3>Bhutan Tour Packages</h3>
                    <span>Immerse yourself in the charm of the Land of the Thunder Dragon, where ancient culture meets breathtaking landscapes. Uncover the mysteries of Bhutan!</span>
                  </figcaption>
                </figure>
              </a>
            </li>
            <li className="portfolio-item col-sm-6 col-xs-12 padding-20">
              <a href="/NepalTour">
                <figure className="animate fadeInRight">
                  <img alt="image5" src="assets/img/frontpage/image5.jpg" />
                  <figcaption>
                    <h3>Nepal Tour Packages</h3>
                    <span>Explore the Himalayan beauty, rich culture, and vibrant traditions. Unforgettable adventures await in the heart of Nepal!</span>
                  </figcaption>
                </figure>
              </a>
            </li>
            {/* //Portfolio Item// */}
            {/* Portfolio Item */}
            
            {/* //Portfolio Item// */}
          </ul>
          {/* End Portfolio */}
        </div>
        <div className="col-md-1">
        </div>
      </div>
    </div>
        
        </div>
  );
}
