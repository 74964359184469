import Copyright from "../components/Copyright";
import CreateDarjeelingTourPackages from "../components/CreateDarjeelingTourPackages";
import DarjeelingTourPackages from "../components/DarjeelingTourPackages";
import Footer from "../components/Footer";
function Darjeeling() {
  return (
    <div className="Darjeeling">


<div id="post_header" className="container" style={{height: '240px'}}>
</div>


<div id="content">

  <DarjeelingTourPackages/>

</div>

<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<div id="content-bottom-border" className="container">
</div>
<CreateDarjeelingTourPackages/>


<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default Darjeeling;