import React, { useState } from 'react';
import Footer from '../components/Footer';
import Copyright from '../components/Copyright';

export default function Contact() {
  const [formData, setFormData] = useState({
    Guest_Name: '',
    Phone_Number: '',
    Email: '',
    Message: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const openWhatsApp = () => {
    const message = `
      *Message* :
      Guests_Name: ${formData.Guest_Name}
      Phone_Number: ${formData.Phone_Number}
      Email: ${formData.Email}
      Message: ${formData.Message}
    `;

    const url = `https://wa.me/918509631121?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your form submission logic here

    // For demonstration purposes, simulate a successful form submission
    setFormSubmitted(true);

    // Reset the form after submission (optional)
    setFormData({
      Guest_Name: '',
      Phone_Number: '',
      Email: '',
      Message: '',
    });

    // Reset the form submission status after 3 seconds
    setTimeout(() => {
      setFormSubmitted(false);
    }, 3000);
  };

  return (
    <div>
      <div id="pre-header" className="container" style={{ height: '240px' }}></div>
      <div id="content-bottom-border" className="container"></div>

      <div id="content" className="container background-white">
        <div className="row margin-vert-30">
          <div className="col-md-9">
            <div className="headline">
              <h2>Contact Form</h2>
            </div>
            <p>
              Please provide as much detail as possible in the message section to help Ramble Networks better understand your needs. Whether you're planning a trip, seeking information about travel packages, or have any other travel-related queries, the experienced staff at Ramble Networks is ready to assist you.
            </p>
            <p>
              Thank you for choosing Ramble Networks for your travel needs. We look forward to serving you and ensuring you have a memorable and seamless travel experience.
            </p>

            {!formSubmitted ? (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name">Name<span className="color-red">*</span></label>
                  <input
                    id="name"
                    className="form-control"
                    type="text"
                    name="Guest_Name"
                    value={formData.Guest_Name}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phoneNumber">Phone Number<span className="color-red">*</span></label>
                  <input
                    id="phoneNumber"
                    className="form-control"
                    type="text"
                    name="Phone_Number"
                    value={formData.Phone_Number}
                    inputMode="numeric"
                    minLength={10}
                    maxLength={10}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Email<span className="color-red">*</span></label>
                  <input
                    id="email"
                    className="form-control"
                    type="text"
                    name="Email"
                    value={formData.Email}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message">Message<span className="color-red">*</span></label>
                  <textarea
                    id="message"
                    className="form-control"
                    name="Message"
                    rows={8}
                    value={formData.Message}
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <p className="animate fadeInUp text-center">
                <button className="btn btn-primary btn-lg" onClick={openWhatsApp}>
                    Send Message
                  </button>
                </p>
              </form>
            ) : (
              <p className="text-center mt-4 text-success fw-bold fs-4">
                We have received your details. We will get back to you shortly.
              </p>
            )}
          </div>

          {/* Side Column */}
          <div className="col-md-3">
            {/* Contact Info */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Contact Info</h3>
              </div>
              <div className="panel-body">
                <p>For personalized travel service, reach out to us. Experience assistance tailored to your needs.</p>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa-phone color-primary" />8509631121
                    
                  </li>
                  <li>
                    <i className="fa-envelope color-primary" />ramblenetworks@gmail.com
                  </li>
                  <li>
                    <i className="fa-home color-primary" />www.ramblenetworks.com
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <strong className="color-primary">Monday-Friday:</strong> 9am to 7pm
                  </li>
                  <li>
                    <strong className="color-primary">Saturday:</strong> 10am to 3pm
                  </li>
                  <li>
                    <strong className="color-primary">Sunday:</strong> Closed
                  </li>
                </ul>
              </div>
            </div>
            {/* End Contact Info */}

            {/* About */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">About</h3>
              </div>
              <div className="panel-body">
                Discover unparalleled travel experiences with our expert guidance. From curated itineraries to personalized assistance, we ensure your journey is memorable. Explore new horizons with us as your travel partner.
              </div>
            </div>
            {/* End About */}
          </div>
          {/* End Side Column */}
        </div>
      </div>
      <div id="content-bottom-border" className="container"></div>
      <div id="pre-header" className="container" style={{ height: '240px' }}></div>

      <Footer />
      <Copyright />
    </div>
  );
}
