import Copyright from "../components/Copyright";
import CreateSikkimTourPackages from "../components/CreateSikkimTourPackages";
import SikkimTourPackages from "../components/SikkimTourPackages";
import Footer from "../components/Footer";
function Sikkim() {
  return (
    <div className="Sikkim">


<div id="post_header" className="container" style={{height: '240px'}}>
</div>


<div id="content">

  <SikkimTourPackages/>

</div>

<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<div id="content-bottom-border" className="container">
</div>
<CreateSikkimTourPackages/>


<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default Sikkim;