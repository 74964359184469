import React from 'react'

export default function Footer() {
  return (
    <div>

        
  <div id="base">
    <div className="container padding-vert-30">
      <div className="row">
        {/* Contact Details */}
        <div className="col-md-4 margin-bottom-20">
          <h3 className="margin-bottom-10">Contact Details</h3>
          <p>
            <span className="fa-phone">Telephone: </span>+918509631121
            <br />
            <span className="fa-envelope">Email: </span>
            <a href="mailto:ramblenetworks@gmail.com">care@ramblenetworks.in</a>
            <br />
            <span className="fa-link">WebSite: </span>
            <a href="www.ramblenetworks.com">www.ramblenetworks.in</a>
          </p>
          <p>NJP Station Road
            <br />Near New Jalpaiguri Railway Station (NJP)
            <br />Bhaktinagar, Siliguri, Dist: Jalpaiguri
            <br />West Bengal - 734007 </p>
        </div>
        {/* End Contact Details */}
        {/* Sample Menu */}
        <div className="col-md-3 margin-bottom-20">
          <h3 className="margin-bottom-10">Travel Menu</h3>
          <ul className="menu">
            <li>
              <a className="fa-tasks" href="/">Exceptional Tour Packages</a>
            </li>
            <li>
              <a className="fa-users" href="/">Effortless Flight, Train, and Bus Tickets</a>
            </li>
            <li>
              <a className="fa-coffee" href="/">Comfortable Hotel Booking</a>
            </li>
            <li>
              <a className="fa-car" href="/">Convenient Car Hire Services</a>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
        {/* End Sample Menu */}
        <div className="col-md-1" />
        {/* Disclaimer */}
        <div className="col-md-3 margin-bottom-20 padding-vert-30 text-center">
          <h3 className="color-gray margin-bottom-10">Join our Newsletter</h3>
          <p>
            Sign up for our newsletter for all the
            <br />latest news and information
          </p>
          <input type="email" />
          <br />

          <a href="https://chat.whatsapp.com/EQggXJ4TOlLHnTPBbiDscw" target="_blank" rel="noopener noreferrer">
  <button className="btn btn-primary btn-lg margin-top-20" type="button">Subscribe</button>
</a>



        </div>
        {/* End Disclaimer */}
        <div className="clearfix" />
      </div>
    </div>
  </div>




    </div>
  );
}
