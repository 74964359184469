import React, { useState, useEffect } from 'react';
import { onValue } from 'firebase/database';
import { OWNPackagesRef } from '../js/firebase'; // Correct references

export default function OwnTourPackages() {
  const [packages, setPackages] = useState([]);
  const [expandedPackageId, setExpandedPackageId] = useState(null);

  useEffect(() => {
    const unsubscribe = onValue(OWNPackagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPackages(Object.keys(data).map((key) => ({ id: key, ...data[key] })));
      } else {
        setPackages([]);
      }
    });
    return () => unsubscribe();
  }, []);

  const createWhatsAppLink = (pkg) => {
    const message = `Booking Details:\nGuest Name: ${pkg.name}\nPhone: ${pkg.phone}\nPackage: ${pkg.adults} Adults, ${pkg.kids} Kids, ${pkg.infants} Infant\nDates: ${pkg.arrival} to ${pkg.departure}\nActivities: ${pkg.days.map((day, i) => `Day ${i + 1}: ${pkg.activities[`day${i + 1}`] || 'No activity specified'}`).join(', ')}`;
    return `https://wa.me/918509631121?text=${encodeURIComponent(message)}`;
  };

  const handleConfirm = (pkg) => {
    const whatsappLink = createWhatsAppLink(pkg);
    window.open(whatsappLink, '_blank');
  };

  const toggleExpand = (packageId) => {
    setExpandedPackageId(expandedPackageId === packageId ? null : packageId);
  };

  const calculateDaysAndNights = (arrival, departure) => {
    const startDate = new Date(arrival);
    const endDate = new Date(departure);
    const totalDays = Math.floor((endDate - startDate) / (1000 * 3600 * 24)) + 1;
    const totalNights = totalDays - 1;
    return { totalDays, totalNights };
  };

  return (
    <div>
      {packages.map((pkg, index) => {
        const { totalDays, totalNights } = calculateDaysAndNights(pkg.arrival, pkg.departure);

        return (
          <React.Fragment key={pkg.id}>
            <div className="container background-white">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <br />
                  <hr />

                  <div style={{ textAlign: 'center' }}>
                    {/* Display the greeting and hide it when clicked */}
                    {expandedPackageId !== pkg.id && (
                      <p
                        style={{ margin: '2px 0', cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={() => toggleExpand(pkg.id)}
                      >
                        {`Hello, ${pkg.name || 'Guest'} - Please Confirm The Details`}
                      </p>
                    )}

                    {/* Display all fields if the package is expanded */}
                    {expandedPackageId === pkg.id && (
                      <div>
                        <p><strong>Guest Name:</strong> {pkg.name || 'N/A'}</p>
                        <p><strong>Contact Number:</strong> {pkg.phone || 'N/A'}</p>
                        <p><strong>Package:</strong> 
                          {` ${pkg.adults} Adults ${pkg.kids} Kids ${pkg.infants} Infant ${totalDays} Days ${totalNights} Nights Tour Package.`}
                        </p>

                        {/* Days and Activities */}
                        <p><strong>Days and Activities:</strong></p>
                        <ul>
                          {pkg.days && pkg.days.length > 0 ? (
                            pkg.days.map((day, i) => (
                              <li key={i}>
                                {`${day} : ${pkg.activities ? pkg.activities[`day${i + 1}`] : 'No Activity Specified'}`}
                              </li>
                            ))
                          ) : (
                            <li>No days specified</li>
                          )}
                        </ul>

                        <p><strong>Tour Ends with Sweet Memories.</strong></p>

                        <p><small className="text-muted">{new Date(pkg.timestamp).toLocaleString()}</small></p>
                      </div>
                    )}
                  </div>
                  <hr />
                </div>
              </div>

              {/* Confirm button */}
              <p className="animate fadeInUp text-center">
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => handleConfirm(pkg)}
                >
                  Confirm
                </button>
              </p>
            </div>

            {index < packages.length - 1 && (
              <div className="container" style={{ height: '20px' }}></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
